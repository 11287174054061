import Link from "next/link";
import Layout from "components/layout";
import { loadStandardContent } from "utils/api";

export default function NotFound(props) {
  return (
    <Layout siteData={props.siteData}>
      <div className="min-h-full pt-16 pb-12 flex flex-col bg-white">
        <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
          <div className="py-8">
            <div className="text-center">
              <p className="text-sm font-semibold text-brand-600 uppercase tracking-wide">
                404 error
              </p>
              <h1 className="mt-4 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                Page not found.
              </h1>
              <p className="mt-4 text-base text-gray-500">
                Sorry, we couldn’t find the page you’re looking for.
              </p>
              <div className="mt-6">
                <a
                  href="/"
                  className="text-base font-medium text-brand-600 hover:text-brand-500"
                >
                  Go back home<span aria-hidden="true"> &rarr;</span>
                </a>
              </div>
            </div>
          </div>
        </main>
      </div>
    </Layout>
  );
}

export async function getStaticProps({ params, preview = false }) {
  const siteData = await loadStandardContent();
  return {
    props: {
      siteData,
    },
  };
}
